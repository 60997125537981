.App {
  text-align: center;
}

* {
  outline: none !important;
}

* {
  outline: none !important;
}

/* start error */

.red-error {
  color: red;
  font-size: medium;
  font-weight: bold;
}

.activeError {
  box-shadow: 0px 8px 8px 0px #ff000040 !important;
  border: 1px solid #ff0000 !important;
}

/* end error */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sidebar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.sidebar::-webkit-scrollbar-corner {
  background-color: #a9a9a9;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #a9a9a9;
}

:not(.layout-fixed) .main-sidebar {
  height: inherit;
  min-height: 100%;
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
}

.pm_disabled {
  color: #d2322d;
}

.pm_enabled {
  color: #47a447;
}

.rdt_TableCol_Sortable div {
  font-size: 15px;
}

.sc-iveFHk div {
  font-size: 20px;
  margin-bottom: 15px;
}

.brand-link .brand-image {
  float: inherit;
  line-height: initial;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3px;
  max-height: initial;
  width: auto;
  box-shadow: none !important;
  height: 65px;
  border-radius: initial;
}

[class*="sidebar-dark-"] {
  background-color: #ffffff;
}

.sidebar-mini.sidebar-collapse .main-sidebar,
.sidebar-mini.sidebar-collapse .main-sidebar::before {
  margin-left: 0;
  width: 0rem;
}

li.navs-header {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  opacity: 1;
  color: #2c6747;
  line-height: 13px;
  padding: 20px 15px 15px;
}

a.main {
  color: #000088 !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link {
  color: #000000;
}

[class*="sidebar-dark"]
  .nav-legacy
  .nav-treeview
  > .nav-item
  > .nav-link.active,
[class*="sidebar-dark"] .nav-legacy .nav-treeview > .nav-item > .nav-link:focus,
[class*="sidebar-dark"]
  .nav-legacy
  .nav-treeview
  > .nav-item
  > .nav-link:hover {
  background: 0 0;
  color: #000;
}

.sidebar-mini.sidebar-collapse .content-wrapper,
.sidebar-mini.sidebar-collapse .main-footer,
.sidebar-mini.sidebar-collapse .main-header {
  margin-left: 0rem !important;
}

.content-wrapper {
  min-height: 798.547px !important;
}

.nav-pills .nav-link {
  border-radius: 0px;
}

.borders {
  padding: 18px 20px;
  border-bottom: 1px solid #000088;
  border-bottom-style: dashed;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #000088;
}

a.nav-link.main.active p {
  color: white;
}

a.nav-link.main.active svg {
  color: white;
  fill: white;
}

a.nav-link.main {
  padding: 0px;
}

.nav-sidebar .nav-link > .right,
.nav-sidebar .nav-link > p > .right {
  top: 0px;
}

a.nav-link.main.active svg path {
  stroke: white !important;
  background: transparent !important;
  color: white !important;
  stroke-width: 0px !important;
}

.dropdown-menu-lg {
  max-width: 180px;
  min-width: 180px;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

img.logo-image {
  width: 250px;
  padding: 10px 10px;
}

.card-primary.card-outline {
  box-shadow: 0px 4px 25px 0px #a8b4d01a;
  border: 1px solid #1848a4;
  border-radius: 7px;
}

.total_users p {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0px;
  color: #1848a4;
  background: #1848a433;
  padding: 20px 10px;
  text-decoration: underline;
}

.d-flex.cardpadding {
  padding-top: 30px;
  justify-content: space-evenly;
  align-items: center;
}

h5.partofphase {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #1848a4;
}

.contentofpages {
  padding: 0px 30px;
  padding-bottom: 20px;
}

.total_users h4 {
  color: #1848a4;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0em;
  margin-bottom: 0px;
}

.card.card-danger.card-outline {
  box-shadow: 0px 4px 25px 0px #a8b4d01a;
  border: 1px solid #850e35;
  border-radius: 7px;
}

.total_users.second p {
  background: #e7cfd7;
  color: #850e35;
}

.total_users.second h4 {
  color: #850e35;
}

.total_users.three p {
  background: #d4ddd6;
  color: #285430;
}

.total_users.three h4 {
  color: #285430;
}

.card.card-success.card-outline {
  box-shadow: 0px 4px 25px 0px #a8b4d01a;
  border: 1px solid #285430;
  border-radius: 7px;
}

.card.card-primary2.card-outline p {
  background: #00000033;
  color: #000000;
}

.card.card-primary2.card-outline {
  box-shadow: 0px 4px 25px 0px #a8b4d01a;
  border: 1px solid #000000;
  border-radius: 7px;
}

.card.card-primary2.card-outline h4 {
  color: black;
}

.card.card-danger2.card-outline p {
  color: #e74c3c;
  background: #e74c3c33;
}

.card.card-danger2.card-outline h4 {
  color: #e74c3c;
}

.card.card-danger2.card-outline {
  border: 1px solid #e74c3c;
}

.card.card-success2.card-outline {
  border: 1px solid #f49d1a;
}

.card.card-success2.card-outline h4 {
  color: #f49d1a;
}

.card.card-success2.card-outline p {
  color: #f49d1a;
  background: #f49d1a33;
}

.card.card-primary1.card-outline {
  border: 1px solid #0278bb;
}

.card.card-primary1.card-outline h4 {
  color: #0278bb;
}

.card.card-primary1.card-outline p {
  color: #0278bb;
  background: #0278bb33;
}

.card.card-danger1.card-outline {
  border: 1px solid #773000;
}

.card.card-danger1.card-outline h4 {
  color: #773000;
}

.card.card-danger1.card-outline p {
  color: #773000;
  background: #77300033;
}

input.form-control {
  height: 50px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

select.form-control {
  height: 50px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

select.form-control {
  height: 50px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

textarea.form-control {
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.custom-file-label {
  height: 50px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding-top: 10px;
}

.custom-file-label::after {
  padding-bottom: 13px;
  height: 50px !important;
  content: "Upload" !important;
  background: #008;
  color: white;
  width: 30%;
  cursor: pointer;
  text-align: center;
  padding-top: 13px;
}

.card {
  background: #ffffff80;
}

.form-group {
  margin-bottom: 30px;
}

.contentofpages .row {
  justify-content: space-evenly;
  align-items: center;
}

.col-form-label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500 !important;
  color: black;
}

a.dsldl {
  top: 16px;
  position: absolute;
  left: -35px;
  color: black;
}

.custom-file {
  height: 50px !important;
}

button.submit123 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  padding: 15px;
  border-radius: 10px;
  display: block;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  border: none;
  width: 35%;
  letter-spacing: 0em;
  background: #008;
  color: white;
}

button.submit1234 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  padding: 15px;
  border-radius: 10px;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border: none;
  width: 35%;
  letter-spacing: 0em;
  background: #f7a11b;
  color: white;
}

.submit1234 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border: none;
  width: 35%;
  letter-spacing: 0em;
  background: #f7a11b;
  color: white;
}

.submit1234 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: block;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  border: none;
  width: 35%;
  letter-spacing: 0em;
  background: #008;
  color: white;
}

.addSubButton {
  padding: 11px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: block;
  /* margin-right: auto; */
  /* margin-bottom: 50px; */
  margin-left: auto;
  border: none;
  width: 10%;
  letter-spacing: 0em;
  background: #008;
  color: white;
}

.main-sidebar {
  bottom: 0;
  float: none;
  height: 100vh !important;
  left: 0;
  position: fixed !important;
  top: 0;
}

.nav-sidebar .nav-link p {
  line-height: 1.2;
}

h4.page-title {
  margin-bottom: 25px;
  color: #008;
  margin-top: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  font-family: "Poppins", sans-serif;
}

[class*="sidebar-dark-"] .sidebar a {
  color: #ce5d3d;
}

i.fa.fa-circle {
  font-size: 5px;
  position: relative;
  top: -4px;
  padding-right: 15px;
  padding-left: 22px;
}

.main-sidebar,
.main-sidebar::before {
  width: 260px;
}

a.naav li {
  padding: 5px 0px 5px 0px;
}

a.naav.active li {
  background: #000088;
  color: white;
}

ul.nav.nav-treeview.dots {
  margin-top: 15px;
}

.sidebar {
  height: 100vh;
  padding-bottom: 120px;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-corner {
  background-color: initial;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

::-webkit-scrollbar-track {
  background-color: initial;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.container-fluid.loginboxpage {
  background-image: url(../src/images/loginbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
}

.loginbg {
  margin-left: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 320px;
}

.login-boxs {
  box-shadow: 0px 0px 4px 0px #00000040;
  background: #ffffff80;
  padding: 3% 6% 6% 6%;
  margin-bottom: 70px;
}

.login-boxs h3 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 1px;
  text-align: center;
}

span.input-group-text {
  background: white;
  border: none !important;
}

.loginboxpage input.form-control {
  box-shadow: none !important;
}

.loginboxpage .form-control {
  display: block;
  border: none !important;
}

.loginboxpage .form-select {
  border: none !important;
}

.login-boxs .input-group {
  height: 52px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: transparent;
  border-radius: 10px;
}

.loginboxpage .form-select:focus {
  box-shadow: none !important;
}

.loginboxpage select.form-select:focus {
  box-shadow: none !important;
}

:focus-visible {
  outline: none !important;
}

.loginboxpage .form-control:focus {
  box-shadow: none !important;
}

.loginboxpage button.btn.btn-primary {
  width: 100%;
  color: #fff;
  background: #833633;
  border: none;
  font-family: "Poppins", sans-serif;
  border-radius: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
}

a.new_account {
  margin-top: 25px;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  color: #e63738;
  text-align: center;
}

hr.wuidth_hr {
  background: #000000;
  width: 90%;
  margin-left: auto;
  display: block;
  margin-right: auto;
  margin-top: 5px;
}

ul.nav.nav-tabs li {
  text-align: center;
  width: 50%;
}

.input-group-btn span {
  cursor: pointer;
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 9;
  top: 37px;
  right: 15px;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: #833633;
  border-color: #833633;
}

p.error {
  margin-bottom: 15px;
  margin-top: -15px;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: #d82221;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 15px 10px;
  color: #ffffff;
  background-color: #adadad;
  border-color: #dee2e6 #dee2e6 #fff;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #008;
  color: #ffffff;
}

a.brand-link {
  padding: 5px 5px;
  border-bottom: 1px solid #a9a9a9;
}

p.rest_pass {
  color: #833633;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 1px;
  text-align: center;
}

.border_name {
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #000000;
  border-style: dashed;
  padding: 0px 50px;
}

.border_names {
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #000000;
  border-style: dashed;
  padding: 35px 53px;
}

.legis_members {
  margin-bottom: 10px;
  margin-top: 50px;
  border: 1px solid #000000;
  border-style: dashed;
  padding: 0px 0px;
}

h4.second-title {
  color: #008;
  margin-top: 35px;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  margin-left: 15px;
  letter-spacing: 0em;
  font-family: "Poppins", sans-serif;
}

h4.third-title {
  color: #008;
  margin-top: 5px;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  font-family: "Poppins", sans-serif;
}

label.col-sm-5.col-form-label {
  font-size: 14px;
}

.col-sm-7 input.form-control {
  height: 40px !important;
}

@media only screen and (max-width: 600px) {
  .border_name {
    margin-left: 10px;
    padding: 0px 0px;
    margin-right: 10px;
  }

  .legis_members {
    margin-left: 10px;
    padding: 0px 0px;
    margin-right: 10px;
  }

  .contentofpages {
    padding: 0px 15px;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #dfebf5;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f0f8ff;
}

table.table.table-striped.mb-0.view_vidhan_mandal thead {
  background: #000088;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0px;
}

.table.table-striped.mb-0.view_vidhan_mandal thead td,
.table.table-striped.mb-0.view_vidhan_mandal thead th {
  padding: 12px 5px;
  border-bottom-width: 2px;
  font-weight: 500;
  font-size: 14px;
}

.table.table-striped.mb-0.view_vidhan_mandal button {
  background: #008;
  border: none;
  color: white;
  padding: 5px 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
}

.table.table-striped.mb-0.view_vidhan_mandal td a {
  color: black;
}

.table.table-striped.mb-0.view_vidhan_mandal td {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  color: black;
  text-align: center;
}

.table.table-striped.mb-0.view_vidhan_mandal td h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 15px;
}

th.heighttab {
  width: 20%;
}

td.scrolltabss p {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: justify;
  height: 115px;
  overflow-y: scroll;
  margin-bottom: 0px;
}

.table-bordered td,
.table-bordered th {
  /* border: 1px solid #000000; */
}

td.scrolltabss {
  padding-right: 0px;
}

td.scrolltabss ::-webkit-scrollbar {
  width: 5px;
}

td.scrolltabss ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

td.scrolltabss ::-webkit-scrollbar-thumb {
  background: #000088;
}

td.scrolltabss ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

td.scrolltabss span {
  width: 95%;
  display: block;
}

table.table.mb-0.gallery_photo thead {
  background-color: white;
}

.table.mb-0.gallery_photo tbody tr:nth-of-type(odd) {
  background-color: #f0f8ff;
}

.table.mb-0.gallery_photo tbody tr:nth-of-type(even) {
  background-color: #dfebf5;
}

.table.mb-0.gallery_photo button {
  background: #008;
  border: none;
  color: white;
  padding: 5px 25px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
}

.table.mb-0.gallery_photo thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  color: #008;
  text-align: center;
}

.table.mb-0.gallery_photo h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

.table.mb-0.gallery_photo td {
  text-align: center;
}

.editt h4 {
  box-shadow: 0px 2px 2px 0px #00000040;
  background: white;
  padding: 9px 5px;
  border-radius: 10px;
}

.editt span {
  box-shadow: 0px 2px 2px 0px #00000040;
  background: white;
  padding: 9px 5px;
  border-radius: 10px;
}

i.fa.fa-edit {
  color: black;
}

a.addpagess {
  float: right;
  color: #008;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

button.addpagess {
  float: right;
  color: #008;
  font-size: 15px;
  font-weight: 600;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
}

a.addpagess img {
  margin-right: 5px;
  cursor: pointer;
}

.respon th,
.respon td {
  min-width: 180px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

table.table.table-striped.table-bordered.mb-0.view_vidhan_mandal.respon {
  overflow-x: scroll;
  display: block;
}

.upload_btn {
  position: relative;
  overflow: hidden;
}

.upload_btn input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.view_vidhan_mandal textarea.form-control {
  height: 112px !important;
}

button.submit1234.mt-5.mb-5 img {
  padding-left: 10px;
}

.submit1234.mt-5.mb-5 img {
  padding-left: 10px;
}

h2.stepper-form {
  margin-bottom: 30px;
  color: #008;
  margin-top: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  font-family: "Poppins", sans-serif;
}

.stepper-buttons {
  display: flex;
  justify-content: space-between;
}

button.nextbutt {
  margin-left: auto;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 10px;
  border-radius: 10px;
  display: block;
  width: 25%;
  margin-bottom: 20px;
  border: none;
  letter-spacing: 0em;
  background: #3ea243;
  color: white;
}

button.prevbutt {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 10px;
  border-radius: 10px;
  width: 25%;
  margin-bottom: 20px;
  border: none;
  letter-spacing: 0em;
  background: #f37e7f;
  color: white;
}

button.submitbutt {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 10px;
  border-radius: 10px;
  width: 25%;
  margin-bottom: 20px;
  border: none;
  letter-spacing: 0em;
  background: #008;
  color: white;
}

.viewmemberprofile ul li {
  margin-right: 23px;
  width: auto !important;
  display: inline-flex;
}

.viewmemberprofile ul {
  justify-content: center;
}

.viewmemberprofile .nav-tabs .nav-link {
  padding: 15px 30px;
  font-size: 18px;
}

.viewmemberprofile .nav-tabs {
  border-bottom: rgb(255, 255, 255);
  margin-top: 20px;
  margin-bottom: 20px;
}

.ssss p {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: black;
}

h4.membername {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 15px;
}

.example-title {
  text-align: center;
  margin-bottom: 60px;
  padding: 3em 0;
  border-bottom: 1px solid #e4eaec;
}

.example-title p {
  margin: 0 auto;
  font-size: 16px;
  max-width: 400px;
}

/*==================================
    TIMELINE
==================================*/
/*-- GENERAL STYLES
------------------------------*/
.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.timeline h1,
.timeline h2,
.timeline h3,
.timeline h4,
.timeline h5,
.timeline h6 {
  margin-bottom: 17px;
  font-size: 18px;
  font-weight: 690;
  line-height: 15px;
  letter-spacing: 0px;
  margin-top: 6px;
}

.row.votes_abcdss h3 {
  color: black;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

h4.eclecresult {
  margin-bottom: 15px;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  color: black;
}

h3.gondiaa {
  font-size: 20px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: 0px;
  text-align: center;
}

.timeline-content p {
  line-height: 1.5;
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 400;
  color: black;
}

/*----- TIMELINE ITEM -----*/
.timeline-item {
  padding-left: 40px;
  position: relative;
}

.timeline-item:last-child {
  padding-bottom: 0;
}

/*----- TIMELINE INFO -----*/
.timeline-info {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}

/*----- TIMELINE MARKER -----*/
.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}

.timeline-marker:before {
  background: #000088;
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}

.timeline::before {
  display: none;
}

.timeline-marker:after {
  content: "";
  width: 2px;
  background: #000000;
  display: block;
  position: absolute;
  top: 22px;
  bottom: 0;
  left: 7px;
}

.timeline-item:last-child .timeline-marker:after {
  content: none;
}

/*----- TIMELINE CONTENT -----*/
.timeline-content {
  padding-bottom: 40px;
}

.timeline-content p:last-child {
  margin-bottom: 0;
}

/*----- TIMELINE PERIOD -----*/
.period {
  padding: 0;
}

.period .timeline-info {
  display: none;
}

.period .timeline-marker:before {
  background: transparent;
  content: "";
  width: 15px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 30px;
  position: absolute;
  border-top: 3px solid #ccd5db;
  border-bottom: 3px solid #ccd5db;
}

.period .timeline-marker:after {
  content: "";
  height: 32px;
  top: auto;
}

.period .timeline-content {
  padding: 40px 0 70px;
}

.period .timeline-title {
  margin: 0;
}

/*----------------------------------------------
    MOD: TIMELINE SPLIT
----------------------------------------------*/
@media (min-width: 768px) {
  .timeline-split .timeline,
  .timeline-centered .timeline {
    display: table;
  }

  .timeline-split .timeline-item,
  .timeline-centered .timeline-item {
    display: table-row;
    padding: 0;
  }

  .timeline-split .timeline-info,
  .timeline-centered .timeline-info,
  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker,
  .timeline-split .timeline-content,
  .timeline-centered .timeline-content,
  .timeline-split .period .timeline-info {
    display: table-cell;
    vertical-align: top;
  }

  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker {
    position: relative;
  }

  .timeline-split .timeline-content,
  .timeline-centered .timeline-content {
    padding-left: 30px;
  }

  .timeline-split .timeline-info,
  .timeline-centered .timeline-info {
    padding-right: 30px;
  }

  .timeline-split .period .timeline-title,
  .timeline-centered .period .timeline-title {
    position: relative;
    left: -45px;
  }
}

/*----------------------------------------------
    MOD: TIMELINE CENTERED
----------------------------------------------*/
@media (min-width: 992px) {
  .timeline-centered,
  .timeline-centered .timeline-item,
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-marker,
  .timeline-centered .timeline-content {
    display: block;
    margin: 0;
    padding: 0;
  }

  .timeline-centered .timeline-item {
    padding-bottom: 40px;
    overflow: hidden;
  }

  .timeline-centered .timeline-marker {
    position: absolute;
    left: 50%;
    margin-left: -7.5px;
  }

  .timeline-centered .timeline-info,
  .timeline-centered .timeline-content {
    width: 50%;
  }

  .timeline-centered > .timeline-item:nth-child(odd) .timeline-info {
    float: left;
    text-align: right;
    padding-right: 30px;
  }

  .timeline-centered > .timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
    padding-left: 30px;
  }

  .timeline-centered > .timeline-item:nth-child(even) .timeline-info {
    float: right;
    text-align: left;
    padding-left: 30px;
  }

  .timeline-centered > .timeline-item:nth-child(even) .timeline-content {
    float: left;
    text-align: right;
    padding-right: 30px;
  }

  .timeline-centered > .timeline-item.period .timeline-content {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .timeline-centered .timeline-item.period {
    padding: 50px 0 90px;
  }

  .timeline-centered .period .timeline-marker:after {
    height: 30px;
    bottom: 0;
    top: auto;
  }

  .timeline-centered .period .timeline-title {
    left: auto;
  }
}

/*----------------------------------------------
    MOD: MARKER OUTLINE
----------------------------------------------*/
.marker-outline .timeline-marker:before {
  background: transparent;
  border-color: #ff6b6b;
}

.marker-outline .timeline-item:hover .timeline-marker:before {
  background: #ff6b6b;
}

label.form-check-label {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500 !important;
  color: black;
  margin-right: 40px;
}

.form-check-input {
  position: relative;
  /* top: 4px; */
  margin-left: inherit;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.button-text {
  position: absolute;
  right: -95px;
}

.toggle-button {
  text-align: center;
  width: 45px;
  top: 7px;
  left: 40px;
  height: 20px;
  background-color: #d8d8d8;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
}

.slider.active {
  background: #009688 !important;
}

.slider {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  transition: transform 0.2s;
  position: absolute;
  top: -1px;
}

.slider {
  background: red !important;
}

.toggle-button.active .slider {
  transform: translateX(30px);
}

.photo_disclaimer {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  color: #2a2a2a;
  margin-bottom: 5px;
  position: relative;
  top: 10px;
}

/* LOADER COMPONENTS */
.rotating-squares-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-top: 250px;
}

.square {
  width: 200px;
  height: 100px;
  background-image: url(../src/images/logo.svg);
  margin: 0 5px;
  animation: rotate 1.5s infinite linear;
}

.square:nth-child(1) {
  transform: rotate(0deg);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.panell {
  background: #000088;
  color: white;
  padding: 20px;
  margin-bottom: 0px;
}

.usetype {
  background: white;
  padding: 30px 25px;
}

.usetype h3 {
  margin-bottom: 25px;
  color: #008;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  font-family: "Poppins", sans-serif;
}

td.markyesorno input[type="checkbox"] {
  width: 15px;
  margin-bottom: 22px;
  height: 15px;
}

.search-wrapper.searchWrapper {
  height: 50px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.chip {
  background: #000088 !important;
}

.fa-check:before {
  content: "\f00c";
  color: white;
  background: green;
  padding: 2px;
  border-radius: 4px;
}

.fa-xmark:before {
  content: "\f00d";
  background: red;
  color: white;
  padding: 2px;
  border-radius: 4px;
}

.dropdown-menu.notouifi {
  height: 400px;
  overflow-y: scroll;
}

.user-panel.pb-3.mb-3.d-flex.align-content-center {
  cursor: pointer;
}

.dropdown-item .fa {
  margin-right: 10px;
}

.dropdown-item {
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  /* background-color: #7ec8a7; */
}

.wrapper .card {
  display: flex;
  padding: 24px;
  border-radius: 5px;
  /* box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.4); */
  min-height: 300px;
  min-width: 400px;
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .card .loading {
  position: relative;
  height: 0;
  width: 0;
}

.wrapper .card .loading span {
  display: block;
  height: 20px;
  width: 20px;
  background: #6464b0;
  position: absolute;
  border-radius: 50%;
}

.wrapper .card .loading span:nth-of-type(1) {
  left: -40px;
  animation: loader1Anim 1.2s linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
}

.wrapper .card .loading span:nth-of-type(2) {
  left: -10px;
  animation: loader1Anim 1.2s linear;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
}

.wrapper .card .loading span:nth-of-type(3) {
  left: 20px;
  animation: loader1Anim 1.2s linear;
  animation-delay: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: forwards;
}

@keyframes loader1Anim {
  from,
  to {
    transform: scale3d(1.15, 0.8, 1);
  }

  42% {
    transform: translateY(-45px);
  }

  88% {
    transform: scale3d(1, 1, 1);
  }

  95% {
    transform: scale3d(1.05, 0.85, 1);
  }

  98% {
    transform: scale3d(1.15, 0.8, 1);
  }
}

.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon {
  display: none;
}

.ck.ck-editor {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 5px;
  margin-bottom: 15px !important;
}

.paginationcss {
  align-items: center;
  justify-content: end;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.paginationnew span {
  display: block;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ddd;
  float: left;
}

.paginationnew button {
  display: block;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ddd;
  float: left;
}

span.active {
  background-color: #000088;
  color: #fff;
}

.dt-length {
  margin-top: 15px;
  margin-left: 5px;
  margin-bottom: 15px;
}

select.dt-length-control {
  height: 35px !important;
  background-color: #ffffff;
  border: 1px solid #000088;
  border-radius: 5px;
  width: 70px;
  margin-right: 10px;
  padding: 0px 10px;
}

.dt-length label {
  color: #008;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.dt-search {
  text-align: right;
}

input.searchtext {
  height: 35px !important;
  background-color: #ffffff;
  border: 1px solid #000088;
  border-radius: 5px;
  margin-left: 10px;
  padding: 0px 10px;
}

.dt-search label {
  color: #008;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.dt-info {
  color: #008;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

li.nav-item.borders.active {
  background: #000088;
}

.sidebar-dark-primary .nav-sidebar.nav-legacy > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar.nav-legacy > .nav-item > .nav-link.active {
  border: none;
}

.file-managerrrr p {
  color: black;
  font-size: 18px;
}

.file-managerrrr {
  text-align: center;
  margin-top: 50px;
}

.file-managerrrr .fa-solid {
  font-size: 80px;
  color: #000089;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* 
input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 56px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 26px;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  color: #000;
  padding: 2px;
  left: 4px;
} */

.css-10vg0a6-MuiResponsiveChart-container {
  width: 100% !important;
  height: 450px !important;
}

ul.overflowwww {
  height: 50px;
  overflow-y: scroll;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

.css-1ald77x {
  z-index: 0 !important;
}
